import { AspectRatio, Image } from '@mantine/core';
import { motion, useAnimationControls } from 'framer-motion';
import { useEffect } from 'react';
import { _animationFormBottomLeft, _animationFormBottomRight, _animationRightToLeft, _animationBottomToTop, _animationHoverButton_Background, _animationHoverButton_Border, _animationHoverButton_Text, _animationHoverGetText_Image, _animationHoverGetText_Background, _animationHoverGetText_Text, _animationLeftToRight, } from '@/libraries/animation';
import { CustomButton } from '@/components/button';

export const Standalone = ({ page, device }) => {
    // Animation controls for banner sections
    const bannerSectionControls = useAnimationControls();

    // Start the animation when the component mounts
    useEffect(() => {
        bannerSectionControls.start('visible');
    }, []);

    // Data for different page banners
    const contentData = {
        contactus: {
            image: '/assets/images/banner_icon_5.png',
            title: 'TALK TO US',
            sub_title: 'We are ready to help!',
            lineImage: '/assets/images/line_yellow_home_1.png',
            titleAnimation: _animationRightToLeft,
            subTitleAnimation: _animationRightToLeft,
            imageAnimation: _animationLeftToRight
        },
        portfolio: {
            image: '/assets/images/banner_icon_4.png',
            title: 'See our works or portfolio',
            sub_title: 'Need some insights on what we can do?',
            lineImage: '', // Update with the correct path for portfolio
            titleAnimation: _animationLeftToRight,
            subTitleAnimation: _animationLeftToRight,
            imageAnimation: _animationRightToLeft
        },
        service: {
            image: '/assets/images/banner_icon_3.png',
            title: ['We are the solution', ' for your IT problems'],
            sub_title: 'Your solution is just one click away!',
            lineImage: '/assets/images/line_yellow_home_1.png',
            titleAnimation: _animationRightToLeft,
            subTitleAnimation: _animationRightToLeft,
            imageAnimation: _animationFormBottomLeft
        },
        about: {
            image: '/assets/images/banner_icon_2.png',
            title: ['“YOUR VISION,', ' OUR MISSION"'],
            sub_title: 'Building a quality website is very important for us. It reflects a business image. By understanding that, we put a lot of effort on building quality website to achieve its goals.',
            lineImage: '',
            titleAnimation: _animationRightToLeft,
            subTitleAnimation: _animationRightToLeft,
            imageAnimation: _animationFormBottomRight,
            animationBannerLineBottomToTop: _animationBottomToTop
        },
        home: {
            image: '/assets/images/banner_icon_1.png',
            title: ['Advance innovation', 'For IT solutions.'],
            sub_title: 'Corebase Technologies has specialized in providing web & mobile development services and helping SME to make their business from offline to online.',
            button: 'Know More',
            buttonLink: '/service',
            titleAnimation: _animationLeftToRight,
            subTitleAnimation: _animationLeftToRight,
            buttonAnimation: _animationLeftToRight,
            imageAnimation: _animationRightToLeft,
        },
    };

    // Destructure data based on the provided page prop
    const {
        image,
        title,
        sub_title,
        lineImage,
        titleAnimation,
        subTitleAnimation,
        imageAnimation,
        animationBannerLineBottomToTop,
        button,
        buttonLink,
        buttonAnimation,
    } = contentData[page];

    // Render content based on the page prop using switch-case
    switch (page) {
        case 'contactus':
            return (
                <div className='about_banner_background_color'>
                    <div className='about_banner_background_container'>
                        <div className='container about_banner_content_position'>
                            <div className='service_banner_content'>
                                <div className='contact_banner_content_left_width'>
                                    <motion.div
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={imageAnimation({ duration: 1 })}
                                    >
                                        <AspectRatio ratio={2317 / 2227}>
                                            <Image width={'100%'} src={image} />
                                        </AspectRatio>
                                    </motion.div>
                                </div>
                                <div className='contact_banner_content_right_width'>
                                    <motion.h1
                                        className='color_primary'
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={titleAnimation({ duration: 1 })}
                                    >
                                        {title}
                                    </motion.h1>
                                    <motion.h2
                                        className='color_primary'
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={subTitleAnimation({ duration: 0.75 })}
                                    >
                                        {sub_title}
                                    </motion.h2>
                                    {device === 'mobile' && (
                                        <div className='about_section_eyw_line'>
                                            <img src={lineImage} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );


        case 'portfolio':
            return (
                <div className='about_banner_background_color'>
                    <div className='portfolio_banner_background_container'>
                        <div className='container portfolio_banner_content_position'>
                            <div className='portfolio_banner_content'>
                                <div className='portfolio_banner_content_left_width'>
                                    <motion.h2
                                        className='color_primary mt_30'
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={subTitleAnimation({ duration: 1 })}
                                    >
                                        {sub_title}
                                    </motion.h2>
                                    <motion.h1
                                        className='color_primary'
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={titleAnimation({ duration: 0.75 })}
                                    >
                                        {title}
                                    </motion.h1>
                                </div>
                                <div className='portfolio_banner_content_right_width'>
                                    <motion.div
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={imageAnimation({ duration: 1 })}
                                    >
                                        <Image
                                            width={'145%'}
                                            src={image}
                                            className='portfolio_banner_content_right_img'
                                        />
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

        case 'service':
            return (
                <div className='about_banner_background_color'>
                    <div className='portfolio_banner_background_container'> {/*Using portfolio because overflow */}
                        <div className='container about_banner_content_position'>
                            <div className='service_banner_content'>
                                <div className='service_banner_content_left_width'>
                                    <motion.div
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={imageAnimation({ duration: 1 })}
                                    >
                                        <AspectRatio ratio={2317 / 2227}>
                                            <Image
                                                width={'100%'}
                                                src={image}
                                            />
                                        </AspectRatio>
                                    </motion.div>
                                </div>
                                <div className='service_banner_content_right_width'>
                                    {device !== 'mobile' && (
                                        <motion.h1
                                            className='color_primary'
                                            initial={"hidden"}
                                            animate={bannerSectionControls}
                                            variants={titleAnimation({ duration: 0.75 })}
                                        >
                                            {title[0]} <br /> {title[1]}
                                        </motion.h1>
                                    )}
                                    {device === 'mobile' && (
                                        <motion.h1
                                            className='color_primary'
                                            initial={"hidden"}
                                            animate={bannerSectionControls}
                                            variants={titleAnimation({ duration: 0.75 })}
                                        >
                                            {title}
                                        </motion.h1>
                                    )}
                                    <motion.h2
                                        className='color_primary mt_30'
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={subTitleAnimation({ duration: 1 })}
                                    >
                                        {sub_title}
                                    </motion.h2>
                                    {device === 'mobile' && (
                                        <div className='about_section_eyw_line'>
                                            <img src={lineImage} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );

        case 'about':
            return (
                <div className='about_banner_background_color'>
                    <div className='about_banner_background_container'>
                        <Image
                            width={'100%'}
                            src="/assets/images/bg_2.png"
                            alt="Logo"
                            withPlaceholder
                            classNames={{
                                root: "about_banner_background_img"
                            }}
                        />
                        <div className='container about_banner_content_position'>
                            <div className='about_banner_content'>
                                <div className='about_banner_content_left_width'>
                                    <motion.div
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={imageAnimation({ duration: 1 })}
                                    >
                                        <AspectRatio ratio={1260 / 1530}>
                                            <Image
                                                width={'100%'}
                                                src={image}
                                            />
                                        </AspectRatio>
                                    </motion.div>
                                </div>
                                <div className='about_banner_content_right_width'>
                                    <motion.h1
                                        className='color_primary'
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={titleAnimation({ duration: 0.75 })}
                                    >
                                        {device !== 'mobile' && (
                                            <div>
                                                {title}
                                            </div>
                                        )}
                                        {device === 'mobile' && (
                                            <motion.span>
                                                {title[0]}
                                            </motion.span>
                                        )}
                                        <br />
                                        {device === 'mobile' && (
                                            <motion.span>
                                                {title[1]}
                                            </motion.span>
                                        )}
                                    </motion.h1>
                                    <motion.h2
                                        className='color_primary mt_30'
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={subTitleAnimation({ duration: 1 })}
                                    >
                                        {sub_title}
                                    </motion.h2>
                                </div>
                                <motion.div
                                    className='about_section_eyw_line1'
                                    initial={"hidden"}
                                    animate={bannerSectionControls}
                                    variants={animationBannerLineBottomToTop}
                                >
                                    <img src="/assets/images/line_yellow_home_2.png" />
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            );

        case 'home':
            return (
                <div className='home_banner_background_color'>
                    <div className='home_banner_background_container'>
                        <Image
                            width={'100%'}
                            src='/assets/images/bg_1.png'
                            alt="Logo"
                            withPlaceholder
                            classNames={{
                                root: "home_banner_background_img"
                            }}
                        />
                        <div className='container home_banner_content_position'>
                            <div className='home_banner_content'>
                                <div className='home_banner_content_left_width'>
                                    <motion.h1
                                        className='color_primary'
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={titleAnimation({ duration: 1 })}
                                    >
                                        <motion.span>
                                            {title[0]}
                                        </motion.span>
                                        <br />
                                        <motion.span>
                                            {title[1]}
                                        </motion.span>
                                    </motion.h1>
                                    <motion.h2
                                        className='color_primary mt_30'
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={subTitleAnimation({ duration: 0.75 })}
                                    >
                                        {sub_title}
                                    </motion.h2>
                                    {device !== 'mobile' ? (
                                        <motion.div
                                            className='color_primary mt_30'
                                            initial={"hidden"}
                                            animate={bannerSectionControls}
                                            variants={buttonAnimation({ duration: 0.5 })}
                                        >
                                            <CustomButton text={button} href={buttonLink} />
                                        </motion.div>
                                    ) : (
                                        <motion.div
                                            className='color_primary mt_30'
                                            initial={"hidden"}
                                            animate={bannerSectionControls}
                                            variants={buttonAnimation({ duration: 0.5 })}
                                        >
                                            <CustomButton borderColorStart={"var(--fourthColor)"} borderColorEnd={"var(--fourthColor)"} text={button} href={buttonLink} />
                                        </motion.div>
                                    )}
                                </div>
                                <div className='home_banner_content_right_width'>
                                    <motion.div
                                        initial={"hidden"}
                                        animate={bannerSectionControls}
                                        variants={imageAnimation({ duration: 1 })}
                                    >
                                        <AspectRatio ratio={919 / 1529}>
                                            <Image
                                                width={'100%'}
                                                src={image}
                                            />
                                        </AspectRatio>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );


        default:
            return null; // Return null if no page prop is provided
    }
}
